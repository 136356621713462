<template>
  <div class="TopUpForm-waapper">
    <el-tabs v-model="currView" type="border-card" @tab-click="changeType">
      <el-tab-pane label="导购员充值" name="StaffTopUp">
        <el-row :gutter="20">
          <el-col :span="3">
            <el-input placeholder="请输入导购员ID" :clearable="true" v-model="KpiUserInfo.kpi_user_id"></el-input>
          </el-col>
          <el-col :span="3">
            <el-input placeholder="请输入导购员姓名" :clearable="true" v-model="KpiUserInfo.name"></el-input>
          </el-col>
          <el-col :span="3">
            <el-input placeholder="请输入手机号" :clearable="true" v-model="KpiUserInfo.mobile"></el-input>
          </el-col>
          <el-col :span="3">
            <el-select :clearable="true" v-model="KpiUserInfo.brandName" placeholder="请选择品牌商">
              <el-option
                v-for="item in BrandAllList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-col>
          <el-button @click="searchKpiUserChargeRecord">搜索</el-button>
          <el-button @click="exportData">导出表格</el-button>
        </el-row>
        <el-row>
          <el-table :data="KpiUserList" style="width:100%;margin:20px 0;" border>
            <el-table-column prop="id" align="center" label="导购员ID">
              <template slot-scope="scope">
                <el-tag type="primary" v-if="scope.row.id==0">活动方代充</el-tag>
                <div v-else>{{scope.row.id}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="name" align="center" label="导购员姓名">
              <template slot-scope="scope">
                <div v-if="scope.row.id==0">无</div>
                <div v-else>{{scope.row.name}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="mobile" align="center" label="手机号">
              <template slot-scope="scope">
                <div v-if="scope.row.id==0">无</div>
                <div v-else>{{scope.row.mobile}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="brandName" align="center" label="所属品牌">
              <template slot-scope="scope">
                <div v-if="scope.row.id==0">无</div>
                <div v-else>{{scope.row.brandName}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="shopName" align="center" label="所属门店">
              <template slot-scope="scope">
                <div v-if="scope.row.id==0">无</div>
                <div v-else>{{scope.row.shopName}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="amount" align="center" label="充值金额"></el-table-column>
            <el-table-column align="center" label="充值方式">
              <template slot-scope="scope">
                <el-tag type="primary" v-if="scope.row.id==0">活动方代充</el-tag>
                <el-tag type="primary" v-else>客户自充</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" align="center" label="充值时间"></el-table-column>
          </el-table>
        </el-row>
        <el-row>
          <el-col :offset="16">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="KpiUserInfo.page"
              :page-size="10"
              layout="total, prev, pager, next, jumper"
              :total="KpiUserTotal"
            ></el-pagination>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="品牌负责人充值" name="BrandTopUp">
        <el-row :gutter="20">
          <el-col :span="3">
            <el-input placeholder="请输入品牌负责人ID" :clearable="true" v-model="BrandInfo.kpi_user_id"></el-input>
          </el-col>
          <el-col :span="3">
            <el-input placeholder="请输入品牌负责人姓名" :clearable="true" v-model="BrandInfo.name"></el-input>
          </el-col>
          <el-col :span="3">
            <el-input placeholder="请输入手机号" :clearable="true" v-model="BrandInfo.mobile"></el-input>
          </el-col>
          <el-col :span="3">
            <el-select :clearable="true" v-model="BrandInfo.brandName" placeholder="品牌商名称">
              <el-option
                v-for="item in BrandAllList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-col>
          <el-button @click="searchBrandChargeRecord">搜索</el-button>
          <el-button @click="exportBrandData">导出表格</el-button>
        </el-row>
        <el-row>
          <el-table :data="BrandList" style="width:100%;margin:20px 0;" border>
            <el-table-column prop="kpiUserId" align="center" label="品牌负责人ID">
              <template slot-scope="scope">
                <el-tag type="primary" v-if="scope.row.kpiUserId==0">活动方代充</el-tag>
                <div v-else>{{scope.row.kpiUserId}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="kpiUserName" align="center" label="品牌负责人姓名">
              <template slot-scope="scope">
                <div v-if="scope.row.kpiUserId==0">无</div>
                <div v-else>{{scope.row.kpiUserName}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="mobile" align="center" label="手机号">
              <template slot-scope="scope">
                <div v-if="scope.row.kpiUserId==0">无</div>
                <div v-else>{{scope.row.mobile}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="brandName" align="center" label="所属品牌">
              <template slot-scope="scope">
                <div v-if="scope.row.kpiUserId==0">无</div>
                <div v-else>{{scope.row.brandName}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="shopName" align="center" label="所属门店">
              <template slot-scope="scope">
                <div v-if="scope.row.kpiUserId==0">无</div>
                <div v-else>{{scope.row.shopName}}</div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="brandBonusBean" align="center" label="品牌奖励豆奖金池"></el-table-column>
            <el-table-column prop="staffBonusBean" align="center" label="导购员奖励豆奖金池"></el-table-column>
            <el-table-column prop="brandPenalty" align="center" label="品牌惩罚承诺金"></el-table-column>
            <el-table-column prop="userShareBonus" align="center" label="客户分享奖励"></el-table-column>
            <el-table-column prop="staffSingleBonus" align="center" label="导购员单项奖励金"></el-table-column>
            <el-table-column prop="liveRedPackage" align="center" label="直播间红包"></el-table-column>
            <el-table-column prop="otherPromotionExpenses" align="center" label="其他推广费用"></el-table-column>-->
            <el-table-column align="center" label="充值类型">
              <template slot-scope="scope">
                <el-tag type="primary" v-if="scope.row.type=='brand_bonus_bean'">品牌奖励豆奖金池</el-tag>
                <el-tag type="primary" v-if="scope.row.type=='staff_bonus_bean'">导购员奖励豆奖金池</el-tag>
                <el-tag type="primary" v-if="scope.row.type=='brand_penalty'">品牌惩罚承诺金</el-tag>
                <el-tag type="primary" v-if="scope.row.type=='user_share_bonus'">客户分享奖励</el-tag>
                <el-tag type="primary" v-if="scope.row.type=='staff_single_bonus'">导购员单项奖励金</el-tag>
                <el-tag type="primary" v-if="scope.row.type=='live_red_package'">直播间红包</el-tag>
                <el-tag type="primary" v-if="scope.row.type=='other_promotion_expenses'">其他推广费用</el-tag>
                <el-tag type="primary" v-if="scope.row.type=='staff_bonus'">导购员奖金池</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="amount" align="center" label="充值金额"></el-table-column>
            <el-table-column align="center" label="充值方式">
              <template slot-scope="scope">
                <el-tag type="primary" v-if="scope.row.kpiUserId==0">活动方代充</el-tag>
                <el-tag type="primary" v-else>客户自充</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" align="center" label="充值时间"></el-table-column>
          </el-table>
        </el-row>
        <el-row>
          <el-col :offset="16">
            <el-pagination
              background
              @current-change="handleCurrentChange2"
              :current-page="BrandInfo.page"
              :page-size="10"
              layout="total, prev, pager, next, jumper"
              :total="BrandTotal"
            ></el-pagination>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="财务充值" name="FinancialTopUp">
        <el-row :gutter="20">
          <el-col :span="3">
            <el-select :clearable="true" v-model="FinanceInfo.type" placeholder="充值类型">
              <el-option
                v-for="item in selectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
          <el-button @click="searchFinanceList">搜索</el-button>
          <el-button @click="exportFinanceData">导出表格</el-button>
        </el-row>
        <el-row>
          <el-table :data="FinanceList" style="width:100%;margin:20px 0;" border>
            <el-table-column prop="id" align="center" label="品牌负责人ID">
              <template slot-scope="scope">
                <el-tag type="primary" v-if="scope.row.id==0">活动方代充</el-tag>
                <div v-else>{{scope.row.id}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="name" align="center" label="品牌负责人姓名">
              <template slot-scope="scope">
                <div v-if="scope.row.id==0">无</div>
                <div v-else>{{scope.row.name}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="mobile" align="center" label="手机号">
              <template slot-scope="scope">
                <div v-if="scope.row.id==0">无</div>
                <div v-else>{{scope.row.mobile}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="amount" align="center" label="充值金额"></el-table-column>

            <el-table-column align="center" label="充值类型">
              <template slot-scope="scope">
                <el-tag type="primary" v-if="scope.row.type=='brand_bonus_bean'">品牌奖励豆奖金池</el-tag>
                <el-tag type="primary" v-if="scope.row.type=='staff_bonus_bean'">导购员奖励豆奖金池</el-tag>
                <el-tag type="primary" v-if="scope.row.type=='brand_penalty'">品牌惩罚承诺金</el-tag>
                <el-tag type="primary" v-if="scope.row.type=='user_share_bonus'">客户分享奖励</el-tag>
                <el-tag type="primary" v-if="scope.row.type=='staff_single_bonus'">导购员单项奖励金</el-tag>
                <el-tag type="primary" v-if="scope.row.type=='live_red_package'">直播间红包</el-tag>
                <el-tag type="primary" v-if="scope.row.type=='other_promotion_expenses'">其他推广费用</el-tag>
                <el-tag type="primary" v-if="scope.row.type=='staff_bonus'">导购员奖金池</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" align="center" label="充值时间"></el-table-column>
          </el-table>
        </el-row>
        <el-row>
          <el-col :offset="16">
            <el-pagination
              background
              @current-change="handleCurrentChange3"
              :current-page="FinanceInfo.page"
              :page-size="10"
              layout="total, prev, pager, next, jumper"
              :total="FinanceTotal"
            ></el-pagination>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MarketingAjax from "@/utils/https/modules/Marketing.request.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import FinanceAjax from "@/utils/https/modules/Finance.request.js";
export default {
  name: "TopUpDetail", //充值记录
  props: {
    activityID: String,
  },
  data() {
    return {
      options: [],
      value: "",
      currentPage4: 0,
      currView: "StaffTopUp",
      form: {
        resource: 1,
      },
      topUpDialog: false,
      selectOptions: [
        { value: "brand_bonus_bean", label: "品牌奖励豆奖金池" },
        { value: "staff_bonus_bean", label: "导购员奖励豆奖金池" },
        { value: "brand_penalty", label: "品牌惩罚承诺金" },
        { value: "user_share_bonus", label: "客户分享奖励" },
        { value: "staff_single_bonus", label: "导购员单项奖励金" },
        { value: "live_red_package", label: "直播间红包" },
        { value: "other_promotion_expenses", label: "其他推广费用" },
        { value: "staff_bonus", label: "导购员奖金池" },
      ],
      tableData: [
        {
          id: 1,
          name: 1,
          brand: 1,
          shop: 1,
          topUpTime: 1,
          topUpAmount: 1,
        },
      ],
      tableData1: [
        {
          id: 1,
          name: 1,
          phone: 1,
          brand: 1,
          shop: 1,
          brandAwardBeanBonusPool: 1,
          staffAwardBeanBonusPool: 1,
          brandPenaltyCommitment: 1,
          customerSharingReward: 1,
          staffIndividualReward: 1,
          liveRedpack: 1,
          otherPromotionFee: 1,
          topUpTotalAmount: 1,
          topUpTime: 1,
        },
      ],
      tableData2: [
        {
          id: 1,
          name: 1,
          phone: 1,
          topUpType: 1,
          topUpAmount: 1,
          topUpTime: 1,
        },
      ],

      // 充值记录查询参数
      KpiUserList: [],
      KpiUserTotal: 0,
      KpiUserInfo: {
        act_id: this.activityID,
        brandName: "",
        kpi_user_id: "",
        mobile: "",
        name: "",
        page: 1,
        size: 10,
      },
      BrandList: [],
      BrandTotal: 0,
      BrandInfo: {
        act_id: this.activityID,
        brandName: "",
        kpi_user_id: "",
        mobile: "",
        name: "",
        page: 1,
        size: 10,
      },
      FinanceList: [],
      FinanceTotal: 0,
      FinanceInfo: {
        actId: this.activityID,
        page: 1,
        size: 10,
        type: "",
      },
      BrandAllList: [],
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.KpiUserInfo.page = val;
      this.getChargeKpiUserChargeRecord();
    },
    handleCurrentChange2(val) {
      this.BrandInfo.page = val;
      this.getChargeBrandChargeRecord();
    },
    handleCurrentChange3(val) {
      this.FinanceInfo.page = val;
      this.getChargeFinanceList();
    },
    searchKpiUserChargeRecord() {
      this.KpiUserList = [];
      this.KpiUserInfo.page = 1;
      this.getChargeKpiUserChargeRecord();
    },
    searchBrandChargeRecord() {
      this.BrandList = [];
      this.BrandInfo.page = 1;
      this.getChargeBrandChargeRecord();
    },
    searchFinanceList() {
      this.FinanceList = [];
      this.FinanceInfo.page = 1;
      this.getChargeFinanceList();
    },
    // 导购员充值记录查询
    async getChargeKpiUserChargeRecord() {
      try {
        this.KpiUserInfo.act_id = this.activityID;
        const data = await FinanceAjax.getChargeKpiUserChargeRecord(
          this.KpiUserInfo
        );
        if (data.data) {
          this.KpiUserList = data.data.list;
          this.KpiUserTotal = data.data.total;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 查询品牌商列表
    async getActivityBrandList() {
      try {
        const data = await ActivityAjax.getActivityBrandList({
          actId: this.activityID,
          catId: "",
          name: "",
          page: 1,
          size: 1000,
        });
        if (data.data) {
          this.BrandAllList = data.data.list;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出导购员充值记录
    async exportData() {
      try {
        const data = await FinanceAjax.ExportChargeKpiUserChargeRecord(
          this.KpiUserInfo
        );
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "导购员充值记录.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 品牌负责人充值记录查询
    async getChargeBrandChargeRecord() {
      try {
        this.BrandInfo.act_id = this.activityID;
        const data = await FinanceAjax.getChargeBrandChargeRecord(
          this.BrandInfo
        );
        if (data.data) {
          this.BrandList = data.data.list;
          this.BrandTotal = data.data.total;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出品牌负责人充值记录查询
    async exportBrandData() {
      try {
        const data = await FinanceAjax.ExportChargeBrandChargeRecord(
          this.BrandInfo
        );
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "品牌负责人充值记录.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 财务充值记录查询
    async getChargeFinanceList() {
      try {
        this.FinanceInfo.actId = this.activityID;
        const data = await FinanceAjax.getChargeFinanceList(this.FinanceInfo);
        if (data.data) {
          this.FinanceList = data.data.list;
          this.FinanceTotal = data.data.total;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出财务充值记录查询
    async exportFinanceData() {
      try {
        const data = await FinanceAjax.ExportChargeFinanceList(
          this.FinanceInfo
        );
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "财务充值记录.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 切换表格列表
    changeType() {
      if (this.currView == "StaffTopUp") {
        this.KpiUserInfo = {
          act_id: this.activityID,
          brandName: "",
          kpi_user_id: "",
          mobile: "",
          name: "",
          page: 1,
          size: 10,
        };
        this.getChargeKpiUserChargeRecord();
      } else if (this.currView == "BrandTopUp") {
        this.BrandInfo = {
          act_id: this.activityID,
          brandName: "",
          kpi_user_id: "",
          mobile: "",
          name: "",
          page: 1,
          size: 10,
        };
        this.getChargeBrandChargeRecord();
      } else if (this.currView == "FinancialTopUp") {
        this.FinanceInfo = {
          actId: this.activityID,
          page: 1,
          size: 10,
          type: "",
        };
        this.getChargeFinanceList();
      }
    },
  },
  created() {
    this.getChargeKpiUserChargeRecord();
    // this.getChargeBrandChargeRecord();
    // this.getChargeFinanceList();
    this.getActivityBrandList();
  },
};
</script>

<style lang="scss" scoped>
.TopUpForm-waapper {
  .form-item-box {
    background: rgba(153, 153, 153, 0.075);
    padding: 40px;
    color: black;
    .item-title {
      font-size: 22px;
      font-weight: 600;
    }
    .item-input-box {
      div {
        margin-right: 40px;
      }
    }
  }
  .option-btn {
    font-size: 13px;
    color: #0c7efe;
    display: inline-block;
    margin-left: 20px;
  }
}
</style>