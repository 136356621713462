var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "TopUpForm-waapper" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.changeType },
          model: {
            value: _vm.currView,
            callback: function($$v) {
              _vm.currView = $$v
            },
            expression: "currView"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "导购员充值", name: "StaffTopUp" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入导购员ID",
                          clearable: true
                        },
                        model: {
                          value: _vm.KpiUserInfo.kpi_user_id,
                          callback: function($$v) {
                            _vm.$set(_vm.KpiUserInfo, "kpi_user_id", $$v)
                          },
                          expression: "KpiUserInfo.kpi_user_id"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入导购员姓名",
                          clearable: true
                        },
                        model: {
                          value: _vm.KpiUserInfo.name,
                          callback: function($$v) {
                            _vm.$set(_vm.KpiUserInfo, "name", $$v)
                          },
                          expression: "KpiUserInfo.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入手机号", clearable: true },
                        model: {
                          value: _vm.KpiUserInfo.mobile,
                          callback: function($$v) {
                            _vm.$set(_vm.KpiUserInfo, "mobile", $$v)
                          },
                          expression: "KpiUserInfo.mobile"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: true,
                            placeholder: "请选择品牌商"
                          },
                          model: {
                            value: _vm.KpiUserInfo.brandName,
                            callback: function($$v) {
                              _vm.$set(_vm.KpiUserInfo, "brandName", $$v)
                            },
                            expression: "KpiUserInfo.brandName"
                          }
                        },
                        _vm._l(_vm.BrandAllList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.name }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    { on: { click: _vm.searchKpiUserChargeRecord } },
                    [_vm._v("搜索")]
                  ),
                  _c("el-button", { on: { click: _vm.exportData } }, [
                    _vm._v("导出表格")
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%", margin: "20px 0" },
                      attrs: { data: _vm.KpiUserList, border: "" }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "id",
                          align: "center",
                          label: "导购员ID"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.id == 0
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("活动方代充")]
                                    )
                                  : _c("div", [_vm._v(_vm._s(scope.row.id))])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          align: "center",
                          label: "导购员姓名"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.id == 0
                                  ? _c("div", [_vm._v("无")])
                                  : _c("div", [_vm._v(_vm._s(scope.row.name))])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "mobile",
                          align: "center",
                          label: "手机号"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.id == 0
                                  ? _c("div", [_vm._v("无")])
                                  : _c("div", [
                                      _vm._v(_vm._s(scope.row.mobile))
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "brandName",
                          align: "center",
                          label: "所属品牌"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.id == 0
                                  ? _c("div", [_vm._v("无")])
                                  : _c("div", [
                                      _vm._v(_vm._s(scope.row.brandName))
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "shopName",
                          align: "center",
                          label: "所属门店"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.id == 0
                                  ? _c("div", [_vm._v("无")])
                                  : _c("div", [
                                      _vm._v(_vm._s(scope.row.shopName))
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "amount",
                          align: "center",
                          label: "充值金额"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "充值方式" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.id == 0
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("活动方代充")]
                                    )
                                  : _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("客户自充")]
                                    )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          align: "center",
                          label: "充值时间"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { offset: 16 } },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          "current-page": _vm.KpiUserInfo.page,
                          "page-size": 10,
                          layout: "total, prev, pager, next, jumper",
                          total: _vm.KpiUserTotal
                        },
                        on: { "current-change": _vm.handleCurrentChange }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "品牌负责人充值", name: "BrandTopUp" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入品牌负责人ID",
                          clearable: true
                        },
                        model: {
                          value: _vm.BrandInfo.kpi_user_id,
                          callback: function($$v) {
                            _vm.$set(_vm.BrandInfo, "kpi_user_id", $$v)
                          },
                          expression: "BrandInfo.kpi_user_id"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入品牌负责人姓名",
                          clearable: true
                        },
                        model: {
                          value: _vm.BrandInfo.name,
                          callback: function($$v) {
                            _vm.$set(_vm.BrandInfo, "name", $$v)
                          },
                          expression: "BrandInfo.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入手机号", clearable: true },
                        model: {
                          value: _vm.BrandInfo.mobile,
                          callback: function($$v) {
                            _vm.$set(_vm.BrandInfo, "mobile", $$v)
                          },
                          expression: "BrandInfo.mobile"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: true, placeholder: "品牌商名称" },
                          model: {
                            value: _vm.BrandInfo.brandName,
                            callback: function($$v) {
                              _vm.$set(_vm.BrandInfo, "brandName", $$v)
                            },
                            expression: "BrandInfo.brandName"
                          }
                        },
                        _vm._l(_vm.BrandAllList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.name }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    { on: { click: _vm.searchBrandChargeRecord } },
                    [_vm._v("搜索")]
                  ),
                  _c("el-button", { on: { click: _vm.exportBrandData } }, [
                    _vm._v("导出表格")
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%", margin: "20px 0" },
                      attrs: { data: _vm.BrandList, border: "" }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "kpiUserId",
                          align: "center",
                          label: "品牌负责人ID"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.kpiUserId == 0
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("活动方代充")]
                                    )
                                  : _c("div", [
                                      _vm._v(_vm._s(scope.row.kpiUserId))
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "kpiUserName",
                          align: "center",
                          label: "品牌负责人姓名"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.kpiUserId == 0
                                  ? _c("div", [_vm._v("无")])
                                  : _c("div", [
                                      _vm._v(_vm._s(scope.row.kpiUserName))
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "mobile",
                          align: "center",
                          label: "手机号"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.kpiUserId == 0
                                  ? _c("div", [_vm._v("无")])
                                  : _c("div", [
                                      _vm._v(_vm._s(scope.row.mobile))
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "brandName",
                          align: "center",
                          label: "所属品牌"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.kpiUserId == 0
                                  ? _c("div", [_vm._v("无")])
                                  : _c("div", [
                                      _vm._v(_vm._s(scope.row.brandName))
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "shopName",
                          align: "center",
                          label: "所属门店"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.kpiUserId == 0
                                  ? _c("div", [_vm._v("无")])
                                  : _c("div", [
                                      _vm._v(_vm._s(scope.row.shopName))
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "充值类型" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.type == "brand_bonus_bean"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("品牌奖励豆奖金池")]
                                    )
                                  : _vm._e(),
                                scope.row.type == "staff_bonus_bean"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("导购员奖励豆奖金池")]
                                    )
                                  : _vm._e(),
                                scope.row.type == "brand_penalty"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("品牌惩罚承诺金")]
                                    )
                                  : _vm._e(),
                                scope.row.type == "user_share_bonus"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("客户分享奖励")]
                                    )
                                  : _vm._e(),
                                scope.row.type == "staff_single_bonus"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("导购员单项奖励金")]
                                    )
                                  : _vm._e(),
                                scope.row.type == "live_red_package"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("直播间红包")]
                                    )
                                  : _vm._e(),
                                scope.row.type == "other_promotion_expenses"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("其他推广费用")]
                                    )
                                  : _vm._e(),
                                scope.row.type == "staff_bonus"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("导购员奖金池")]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "amount",
                          align: "center",
                          label: "充值金额"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "充值方式" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.kpiUserId == 0
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("活动方代充")]
                                    )
                                  : _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("客户自充")]
                                    )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          align: "center",
                          label: "充值时间"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { offset: 16 } },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          "current-page": _vm.BrandInfo.page,
                          "page-size": 10,
                          layout: "total, prev, pager, next, jumper",
                          total: _vm.BrandTotal
                        },
                        on: { "current-change": _vm.handleCurrentChange2 }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "财务充值", name: "FinancialTopUp" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: true, placeholder: "充值类型" },
                          model: {
                            value: _vm.FinanceInfo.type,
                            callback: function($$v) {
                              _vm.$set(_vm.FinanceInfo, "type", $$v)
                            },
                            expression: "FinanceInfo.type"
                          }
                        },
                        _vm._l(_vm.selectOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-button", { on: { click: _vm.searchFinanceList } }, [
                    _vm._v("搜索")
                  ]),
                  _c("el-button", { on: { click: _vm.exportFinanceData } }, [
                    _vm._v("导出表格")
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%", margin: "20px 0" },
                      attrs: { data: _vm.FinanceList, border: "" }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "id",
                          align: "center",
                          label: "品牌负责人ID"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.id == 0
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("活动方代充")]
                                    )
                                  : _c("div", [_vm._v(_vm._s(scope.row.id))])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          align: "center",
                          label: "品牌负责人姓名"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.id == 0
                                  ? _c("div", [_vm._v("无")])
                                  : _c("div", [_vm._v(_vm._s(scope.row.name))])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "mobile",
                          align: "center",
                          label: "手机号"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.id == 0
                                  ? _c("div", [_vm._v("无")])
                                  : _c("div", [
                                      _vm._v(_vm._s(scope.row.mobile))
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "amount",
                          align: "center",
                          label: "充值金额"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "充值类型" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.type == "brand_bonus_bean"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("品牌奖励豆奖金池")]
                                    )
                                  : _vm._e(),
                                scope.row.type == "staff_bonus_bean"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("导购员奖励豆奖金池")]
                                    )
                                  : _vm._e(),
                                scope.row.type == "brand_penalty"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("品牌惩罚承诺金")]
                                    )
                                  : _vm._e(),
                                scope.row.type == "user_share_bonus"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("客户分享奖励")]
                                    )
                                  : _vm._e(),
                                scope.row.type == "staff_single_bonus"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("导购员单项奖励金")]
                                    )
                                  : _vm._e(),
                                scope.row.type == "live_red_package"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("直播间红包")]
                                    )
                                  : _vm._e(),
                                scope.row.type == "other_promotion_expenses"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("其他推广费用")]
                                    )
                                  : _vm._e(),
                                scope.row.type == "staff_bonus"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("导购员奖金池")]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          align: "center",
                          label: "充值时间"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { offset: 16 } },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          "current-page": _vm.FinanceInfo.page,
                          "page-size": 10,
                          layout: "total, prev, pager, next, jumper",
                          total: _vm.FinanceTotal
                        },
                        on: { "current-change": _vm.handleCurrentChange3 }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }